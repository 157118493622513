document.addEventListener( 'DOMContentLoaded', function() {
  const tables = document.querySelectorAll( '.bs-section--legal-privacy-terms table' );
  
  tables.forEach( function( table ) {
    const headers = Array.from( table.querySelectorAll( 'thead th' ) );

    const rows = table.querySelectorAll( 'tbody tr' );
  
    rows.forEach( function( row ) {
      const cells = row.querySelectorAll( 'td' );
  
      cells.forEach( function( cell, index ) {
        const headerText = headers[index].textContent;
        cell.setAttribute( 'data-label', headerText );
      } );
    } );
  } );
} );