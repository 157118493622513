$( $ => {
  const body = $( 'body' );
  const notificationBar = $( '#notify-bar' );
  if ( !notificationBar.length ) {
    return false;
  }
  
  $( '.notify-bar__close' ).on( 'click', () => {
    notificationBar.slideUp( 400, () => {
      body.removeClass( 'notify-bar-enable' );
    } );
    $.ajax( {
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: {
        action: 'mark_notify_bar_as_read',
      },
    } );
  } );
} );
